<template>
    <div>
        <DataTableHeader
            :pagination="pagination"
            :searchAttributes="searchAttributes"
            :selectedSearchAttributes.sync="selectedSearchAttributes"
            :disabled="loading"
        />
        <DataTableSkeletonLoader :loading="initialLoad"/>
        <v-data-table
            v-show="!initialLoad"
            :headers="headers"
            :items="entities"
            :dense="$vuetify.breakpoint.xs && !loading"
            :loading="loading"
            :loading-text="$t('components.noData.fetchingEntries')"
            :options.sync="options"
            :server-items-length="pagination.total"
            :items-per-page="pagination.per_page"
            :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
            :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
            :item-class="highlightRowIfUserBalanceLow"
            hide-default-footer
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ deviceType === 'computers' ? 'PC' : 'PS' }}</v-toolbar-title>
                    <v-divider
                        class="mx-4"
                        inset
                        vertical
                    ></v-divider>
                    <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                    <RefreshButton 
                        :refresh="refresh"
                        :loading="loading"
                    />
                    <v-dialog
                        v-model="viewDialog"
                        max-width="1000px"
                    >
                        <v-card>
                            <v-card-text class="px-0 pb-0">
                                <v-toolbar
                                    class="elevation-2"
                                    color="primary"
                                >
                                    <v-toolbar-title class="ml-0">
                                        <span class="font-weight-bold white--text">{{ $t('entities.users.viewEntity') }}</span>
                                    </v-toolbar-title>
                                    <v-spacer/>
                                    <v-btn
                                        @click="viewDialog = false"
                                        icon
                                        dark
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-container>
                                    <v-card>
                                        <v-simple-table
                                            class="do-not-apply-hover"
                                            dense
                                        >
                                            <template 
                                                v-if="viewDialog"
                                                v-slot:default
                                            >
                                                <thead>
                                                    <tr>
                                                        <th 
                                                            colspan="3"
                                                            class="text-left"
                                                        >
                                                            {{ $t('entities.orders.generalInformation') }}
                                                        </th>
                                                        <th class="text-right">
                                                            #{{ editedEntity.id }}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{{ $t('entities.users.attributes.username') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ editedEntity.username }}
                                                        </td>
                                                        <td>{{ $t('entities.roles.title') }}</td>
                                                        <td class="font-weight-bold">
                                                            <v-chip
                                                                v-for="(role, index) in editedEntity.roles"
                                                                color="primary"
                                                                :key="`role-${index}`"
                                                                class="font-weight-bold mr-2"
                                                                text-color="white"
                                                                small
                                                            >
                                                                {{ role }}
                                                            </v-chip>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('entities.users.attributes.active') }}</td>
                                                        <td class="font-weight-bold">
                                                            <Boolean :boolean="editedEntity.active"/>
                                                        </td>
                                                        <td>{{ $t('entities.users.attributes.banned') }}</td>
                                                        <td class="font-weight-bold">
                                                            <Boolean :boolean="editedEntity.banned"/>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('generalAttributes.coins') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ addThousandSeparator(editedEntity.wallet.balance) }}
                                                            <CoinImage/>
                                                        </td>
                                                        <td>{{ $t('entities.ranks.attributes.experience') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ addThousandSeparator(editedEntity.rank.experience) }}
                                                            <ExperienceImage class="pt-1"/>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('entities.users.attributes.rank') }}</td>
                                                        <td class="font-weight-bold">
                                                            <v-chip
                                                                color="primary"
                                                                class="font-weight-bold mr-2"
                                                                text-color="white"
                                                                small
                                                            >
                                                                {{ editedEntity.rank.info.name }}
                                                            </v-chip>
                                                        </td>
                                                        <td>{{ $t('entities.users.attributes.registeredAt') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ fromUtcDateTimeToCurrent(editedEntity.created_at, true, true) }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ $t('entities.users.attributes.cardId') }}</td>
                                                        <td class="font-weight-bold">
                                                            {{ editedEntity.card_id || '/' }}
                                                        </td>
                                                        <td>{{ $t('entities.users.attributes.avatar') }}</td>
                                                        <td class="font-weight-bold">
                                                            <template v-if="editedEntity.avatar">
                                                                <img
                                                                    :src="editedEntity.avatar"
                                                                    alt="Avatar"
                                                                    width="20px"
                                                                />
                                                            </template>
                                                            <template v-else>
                                                                /
                                                            </template>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <thead>
                                                    <tr>
                                                        <th 
                                                            colspan="4"
                                                            class="text-left"
                                                        >
                                                            {{ $t('entities.users.identity') }}
                                                            <Status 
                                                                v-if="editedEntity.identity"
                                                                :status="editedEntity.identity.status"
                                                            />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-if="editedEntity.identity && editedEntity.identity.id">
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.fullName') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.identity.name || '/' }}
                                                            </td>
                                                            <td>{{ $t('generalAttributes.address') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.identity.address || '/' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.users.attributes.birthdate') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ editedEntity.identity.birthdate || '/' }}
                                                            </td>
                                                            <td>{{ $t('miscellaneous.createdAt') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ fromUtcDateTimeToCurrent(editedEntity.identity.created_at, true, true) }}
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <template v-else>
                                                        <tr>
                                                            <td colspan="4">
                                                                <i>{{ $t('entities.users.noIdentitySet') }}</i>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                        <v-row 
                                            v-if="editedEntity.identity && editedEntity.identity.id && editedEntity.identity.status === 'PENDING'"
                                            align="center"
                                            class="my-2"
                                        >
                                            <v-col
                                                cols="12"
                                                class="text-center"
                                            >
                                                <v-btn
                                                    @click="approveIdentity(editedEntity.id)"
                                                    :disabled="approvingIdentity || rejectingIdentity"
                                                    :loading="approvingIdentity"
                                                    color="success"
                                                    class="mx-2"
                                                    small
                                                >
                                                    <span>{{ approvingIdentity ? $t('entities.users.approvingIdentity') : ($t(`entities.users.approveIdentity`)) }}</span>
                                                </v-btn>
                                                <v-btn
                                                    @click="rejectIdentity(editedEntity.id)"
                                                    :disabled="approvingIdentity || rejectingIdentity"
                                                    :loading="rejectingIdentity"
                                                    color="error"
                                                    class="mx-2"
                                                    small
                                                >
                                                    <span>{{ rejectingIdentity ? $t('entities.users.rejectingIdentity') : ($t(`entities.users.rejectIdentity`)) }}</span>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-simple-table
                                            class="do-not-apply-hover"
                                            dense
                                        >
                                            <template 
                                                v-if="viewDialog"
                                                v-slot:default
                                            >
                                                <thead>
                                                    <tr>
                                                        <th 
                                                            colspan="4"
                                                            class="text-left"
                                                        >
                                                            {{ $t('entities.users.attributes.email') }}
                                                            <Status 
                                                                v-if="editedEntity.email"
                                                                :status="editedEntity.email.status"
                                                            />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <template v-if="editedEntity.email">
                                                            <td>{{ $t('generalAttributes.address') }}</td>
                                                            <td class="font-weight-bold">
                                                                <CopyTooltip :text="editedEntity.email.address"/>
                                                            </td>
                                                            <td>{{ $t('miscellaneous.createdAt') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ fromUtcDateTimeToCurrent(editedEntity.email.created_at, true, true) }}
                                                            </td>
                                                        </template>
                                                        <template v-else>
                                                            <td colspan="4">
                                                                <i>{{ $t('entities.users.noEmailSet') }}</i>
                                                            </td>
                                                        </template>
                                                    </tr>
                                                </tbody>
                                                <thead>
                                                    <tr>
                                                        <th 
                                                            colspan="4"
                                                            class="text-left"
                                                        >
                                                            {{ $t('entities.users.attributes.phone') }}
                                                            <Status 
                                                                v-if="editedEntity.phone"
                                                                :status="editedEntity.phone.status"
                                                            />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <template v-if="editedEntity.phone">
                                                            <td>{{ $t('entities.orders.attributes.number') }}</td>
                                                            <td class="font-weight-bold">
                                                                <CopyTooltip :text="`+${editedEntity.phone.number}`"/>
                                                            </td>
                                                            <td>{{ $t('miscellaneous.createdAt') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ fromUtcDateTimeToCurrent(editedEntity.phone.created_at, true, true) }}
                                                            </td>
                                                        </template>
                                                        <template v-else>
                                                            <td colspan="4">
                                                                <i>{{ $t('entities.users.noPhoneSet') }}</i>
                                                            </td>
                                                        </template>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card>
                                </v-container>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn
                                    @click="viewDialog = false"
                                    color="dark darken-1"
                                    text
                                >
                                    {{ $t('miscellaneous.ok') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog
                        v-model="awardDialog"
                        max-width="600px"
                    >
                        <v-card>
                            <v-card-text class="px-0 pb-0">
                                <v-toolbar
                                    class="elevation-2"
                                    color="primary"
                                >
                                    <v-toolbar-title class="ml-0">
                                        <span class="font-weight-bold white--text">{{ $t('entities.users.awardUser') }} {{ editedEntity.username }}</span>
                                    </v-toolbar-title>
                                    <v-spacer/>
                                    <v-btn
                                        @click="awardDialog = false"
                                        icon
                                        dark
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-container>
                                    <v-card class="px-4 pt-4">
                                        <v-row align="center">
                                            <RequiredFields/>
                                            <v-col cols="12">
                                                <v-form
                                                    v-if="awardDialog"
                                                    @submit.prevent="awardEvent"
                                                    method="POST"
                                                    ref="awardForm"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="pt-4 pb-0"
                                                    >
                                                        <v-select
                                                            v-model="editedEntity.events"
                                                            :items="filteredEvents"
                                                            :rules="[v => !!v.length || $t('validation.required')]"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                            :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                            :disabled="awardingEvent"
                                                            item-text="name"
                                                            class="do-not-apply-transform"
                                                            prepend-inner-icon="mdi-trophy-award"
                                                            return-object
                                                            multiple
                                                            solo
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('entities.events.title') }}
                                                                <span :style="{ color: 'red' }">*</span>
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="text-center pt-0"
                                                    >
                                                        <v-btn
                                                            @click="awardEvent"
                                                            :disabled="awardingEvent"
                                                            :loading="awardingEvent"
                                                            color="primary"
                                                        >
                                                            <span>{{ awardingEvent ? $t('entities.users.awarding') : ($t(`entities.users.awardProgress`)) }}</span>
                                                        </v-btn>
                                                    </v-col>
                                                </v-form>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-container>
                            </v-card-text>
                            <v-divider/>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn
                                    @click="awardDialog = false"
                                    color="dark darken-1"
                                        class="mr-3"
                                    text
                                >
                                    {{ $t('miscellaneous.cancel') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>    
            </template>
            <template v-slot:item.username="{ item }">
                <v-tooltip
                    color="transparent"
                    v-if="item.last_polling_date && !wasPolledInLastNMinutes(item, 1)"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-on="on"
                            color="yellow"
                            medium
                            class="mr-2"
                        >
                        mdi-alert
                        </v-icon>
                    </template>
                    <v-card class="elevation-2 px-2 py-2">
                        <span> {{ $t('entities.users.connectivityIssue', {minutes: Math.round((new Date() - new Date(item.last_polling_date)) / 60000)}) }} </span>
                    </v-card>
                </v-tooltip>
                <span>{{ item.username }}</span>
            </template>
            <template v-slot:item.arena_id="{ item }">
                <template v-if="item.arena_id">
                    {{ item.arena_id }}
                </template>
            </template>
            <template v-slot:item.balance="{ item }">
                <v-skeleton-loader
                    :loading="item.balance === -0.5"
                    type="image"
                    width="30px"
                    height="15px"
                    class="d-inline-block"
                    :style="{ marginTop : `${item.balance === -0.5 ? '2' : '0'}px`}"
                >
                   {{ addThousandSeparator(item.balance) }}
                </v-skeleton-loader>
                <CoinImage class="ml-1"/>
            </template>
            <template v-slot:item.base_rate="{ item }">
                {{ addThousandSeparator(item.rate_per_minute) }}
                <CoinImage/>/min
                ({{ addThousandSeparator(item.base_rate) }} <CoinImage/>/h)
                <template v-if="item.pass">
                    <br>
                    Pass: {{ item.pass.valid_from_local }} - {{ item.pass.valid_to_local }}
                </template>
                <v-tooltip
                    v-if="item.rate_per_minute >= 0 || item.negative_balance < 0"
                    color="transparent"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <v-icon
                            v-on="on"
                            :color="shouldChangeRowStyle(item) ? 'white' : 'primary'"
                            small
                        >
                            mdi-information
                        </v-icon>
                    </template>
                    <v-card class="elevation-2 px-2 py-1">
                        <v-simple-table
                            class="do-not-apply-hover"
                            dense
                        >
                            <template v-slot:default>
                                <tbody>
                                    <tr v-if="item.free_until">
                                        <td 
                                            class="text-center px-0"
                                            colspan="4"
                                        >
                                            {{ $t('entities.sessions.startForFreeUntil') }}: <span class="font-weight-bold">{{ fromUtcDateTimeToCurrent(item.free_until, true, true) }}</span>
                                        </td>
                                    </tr>
                                    <tr v-if="item.negative_balance < 0">
                                        <td 
                                            class="text-center px-0"
                                            colspan="4"
                                        >
                                            {{ $t('entities.sessions.negativeBalance') }}: <span class="font-weight-bold">{{ addThousandSeparator(item.negative_balance) }} <CoinImage/></span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0">{{ $t('entities.sessions.bonuses.rank') }}</td>
                                        <td class="font-weight-bold">
                                            {{ item.rank_bonus }}%
                                        </td>
                                        <td>{{ $t('entities.sessions.bonuses.reward') }}</td>
                                        <td class="font-weight-bold pr-0">
                                            {{ item.reward_bonus }}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0">{{ $t('entities.sessions.bonuses.subscription') }}</td>
                                        <td class="font-weight-bold">
                                            {{ item.subscription_bonus }}%
                                        </td>
                                        <td>{{ $t('entities.sessions.bonuses.arena') }}</td>
                                        <td class="font-weight-bold pr-0">
                                            {{ item.arena_bonus }}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="pl-0">{{ $t('entities.sessions.bonuses.skill') }}</td>
                                        <td class="font-weight-bold">
                                            {{ item.skill_bonus }}%
                                        </td>
                                        <td>{{ $t('entities.sessions.bonuses.legend') }}</td>
                                        <td class="font-weight-bold pr-0">
                                            {{ item.legend_bonus }}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td 
                                            class="text-center px-0"
                                            colspan="4"
                                        >
                                            {{ $t('entities.sessions.bonuses.formula') }} = 1 - (1 - <b>{{ !!item.legend_bonus? item.legend_bonus : item.rank_bonus }}%</b>) * (1 - <b>{{ item.reward_bonus }}%</b>) * (1 - <b>{{ item.subscription_bonus }}%</b>) * (1 - <b>{{ item.skill_bonus }}%</b>) * (1 - <b>{{ item.arena_bonus }}%</b>)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td 
                                            class="text-center px-0"
                                            colspan="4"
                                        >
                                            {{ $t('entities.sessions.bonuses.total') }}: <span class="font-weight-bold">{{ item.total_bonus }}%</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td 
                                            class="text-center px-0"
                                            colspan="4"
                                        >
                                            {{ $t('entities.sessions.daily_boost.daily_boost') }}: <span class="font-weight-bold">{{ item.daily_boost.percentage }}%</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                </v-tooltip>
            </template>
            <template v-slot:item.started_at="{ item }">
                {{ fromUtcDateTimeToCurrent(item.started_at, true, true) }} ({{ Math.round(item.duration / 60) }} min)
            </template>
            <template v-if="deviceType === 'computers'" v-slot:item.currentlyPlaying="{ item }">
                <v-tooltip top color="transparent" v-if="item.games && item.games.length > 0">
                    <template v-slot:activator="{ on }">
                        <v-avatar size="36px">
                            <img
                                v-on="on"
                                :src="item.games[0].game.icon || item.games[0].game.image"
                                alt=""
                            />
                        </v-avatar>
                    </template>
                    <v-card>{{ item.games[0].game.name }}</v-card>
                </v-tooltip>
                <span v-if="item.games && item.games.length"> ({{ Math.round(item.games[0].duration / 60) }} min)</span>
            </template>
            <template v-slot:item.action="{ item }">
                <ViewButton
                    :text="$t('entities.users.viewEntity')"
                    :item="item"
                    :openViewDialog="openViewDialog"
                />
                <v-tooltip
                    color="transparent"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="openAwardDialog(item)"
                            v-on="on"
                            :disabled="loadingAward"
                            color="warning"
                            class="glow ml-2"
                            depressed
                            x-small
                            fab
                        >
                            <template v-if="loadingAward">
                                <ProgressCircular color="warning"/>
                            </template>
                            <template v-else>
                                <v-icon
                                    class="white--text"
                                    small
                                >
                                    mdi-trophy-variant-outline
                                </v-icon>
                            </template>
                        </v-btn>
                    </template>
                    <v-card class="elevation-2 px-4 py-2">
                        <span>{{ $t('entities.users.awardUser') }}</span>
                    </v-card>
                </v-tooltip>
                <v-tooltip
                    color="transparent"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="createPurchase(item)"
                            v-on="on"
                            :disabled="!$store.getters.arena"
                            class="glow ml-2"
                            color="info"
                            depressed
                            x-small
                            fab
                        >
                            <v-icon
                                color="white"
                                small
                            >
                                shop
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-card class="elevation-2 px-4 py-2">
                        <span>{{ $t('entities.users.shop') }}</span>
                    </v-card>
                </v-tooltip>
                <v-tooltip
                    color="transparent"
                    top
                >
                    <template v-slot:activator="{ on }">
                        <v-btn
                            @click="stopSession(item)"
                            v-on="on"
                            :disabled="!$store.getters.arena || loading || loadingIndexes.includes(item.id)"
                            color="error"
                            class="glow ml-2"
                            depressed
                            x-small
                            fab
                        >
                            <template v-if="loadingIndexes.includes(item.id)">
                                <ProgressCircular color="error"/>
                            </template>
                            <template v-else>
                                <v-icon
                                    class="white--text"
                                    small
                                >
                                    power_settings_new
                                </v-icon>
                            </template>
                        </v-btn>
                    </template>
                    <v-card class="elevation-2 px-4 py-2">
                        <span>{{ $t('entities.sessions.stop') }}</span>
                    </v-card>
                </v-tooltip>
            </template>
            <template v-slot:no-data>
                 <NoData
                    :pagination="pagination"
                    :typing="typing"
                    :fetchEntities="fetchEntities"
                />
            </template>
        </v-data-table>
        <DataTableFooter
            :pagination="pagination"
            :disabled="loading"
        />
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import CopyTooltip from '@/components/CopyTooltip.vue';
import Status from '@/components/Status.vue';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import NoData from '@/components/crud/NoData.vue';
import CoinImage from '@/components/CoinImage.vue';
import ViewButton from '@/components/crud/view/ViewButton.vue';
import ProgressCircular from '@/components/ProgressCircular.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import ExperienceImage from '@/components/ExperienceImage.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import Session from "@/models/main/Session";
import User from '@/models/users/User';
import Snackbar from '@/components/Snackbar.vue';

import thousandSeparator from '@/mixins/thousand-separator';
import { axiosCatch } from "@/mixins/crud/methods/axios-catch";
import { paginationWatch } from "@/mixins/crud/watch/pagination";
import convertDate from '@/mixins/convert-date';
import convertCase from '@/mixins/convert-case';
import isRole from '@/mixins/crud/computed/isRole';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";

export default Vue.extend({

    props: {
        deviceType: {
            type: String
        }
    },

    mixins: [
        thousandSeparator,
        convertCase,
        axiosCatch,
        convertDate,
        isRole,
        paginationWatch,
        showSnackbar,
    ],

    components: {
        Boolean,
        Status,
        CopyTooltip,
        CoinImage,
        NoData,
        ProgressCircular,
        Snackbar,
        ViewButton,
        ExperienceImage,
        RequiredFields,
        RefreshButton,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
    },

    data: () => ({
        interval: null,
        initialLoad: true,
        loading: false,
        viewDialog: false,
        awardDialog: false,
        loadingAward: false,
        awardingEvent: false,
        approvingIdentity: false,
        rejectingIdentity: false,
        typing: false,
        loadingIndexes: [],
        entities: [],
                events: [
            {
                name: 'Win with a friend',
                context: {
                    event: 'win',
                    type: 'win-with-friend'
                }
            },
            {
                name: 'Instagram Follow',
                context: {
                    event: 'follow',
                    platform: 'instagram'
                }
            },
            {
                name: 'TikTok Follow',
                context: {
                    event: 'follow',
                    platform: 'tiktok'
                }
            },
            {
                name: 'Significant Other Visit',
                context: {
                    event: 'courage'
                }
            },
            {
                name: 'Staff Drink Treat',
                context: {
                    event: 'staff-treat',
                    type: 'drink',
                }
            },
            {
                name: 'Birthday Party',
                context: {
                    event: 'birthday-party'
                }
            },
            {
                name: 'FF League Participation',
                context: {
                    event: 'ff-league-participation'
                }
            },
            {
                name: 'FF Competition Win',
                context: {
                    event: 'win',
                    type: 'ff-competition'
                }
            },
            {
                name: 'LEGEND',
                context: {
                    event: 'legend-achieved'
                }
            },
        ],
        editedEntity: {
            id: null,
            user_id: null,
            achievements: [],
            quests: [],
            events: [],
        },
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        selectedSearchAttributes: ['Username'],
    }),

    computed: {
        headers() {
            const headers = [
                { text: this.$t('entities.users.attributes.username'), align: 'left', value: 'username' },
                { text: this.$t('entities.devices.entity'), align: 'left', value: 'device_id' },
                { text: this.$t('generalAttributes.coins'), align: 'left', value: 'balance', sortable: false },
                { text: this.$t('playingNow.headers.rate'), align: 'left', value: 'base_rate', sortable: false },
                { text: this.$t('entities.sessions.attributes.startedAt'), align: 'left', value: 'started_at' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];

            if (!this.$store.getters.arena) {
                headers.splice(0, 0, { text: this.$t('miscellaneous.arena'), align: 'left', value: 'arena.name', sortable: false })
            }

            if (this.deviceType === "computers") {
                headers.splice(5, 0, { text: this.$t('adminDashboard.currentlyPlaying'), align: 'left', value: 'currentlyPlaying' });
            }

            return headers;
        },
        filteredEvents() {
            const filteredEvents = this.events;
            if (!this.isAdmin) {
                // Remove legend from list if not admin
                filteredEvents.splice(filteredEvents.findIndex(a => a.name.toLowerCase() === "legend"), 1)
            }
            return filteredEvents;
        },
        searchAttributes() {
            return [
                { label: this.$t('entities.users.attributes.username'), value: 'Username' },
                { label: this.$t('entities.devices.entity'), value: 'Device' },
            ]
        },
        arenaName() {
            return this.$store.getters.arena ? `${this.titleToKebabCase(this.$store.getters.arena.name)}` : '';
        }
    },

    watch: {
        awardDialog(val) {
            if (!val) {
                this.editedIndex = -1;
                this.$set(this.editedEntity, 'events', []);
            }
        },
    },

    mounted() {
        this.refresh();
        this.interval = setInterval(() => {
            this.refresh();
        }, 30000) // Every 30 seconds
    },

    beforeDestroy() {
        clearInterval(this.interval);
    },

    methods: {
        async fetchEntities() {
            try {
                let append = this.setParams();

                this.loading = true;
                let response;
                // If searched by specific device, change param, else, default search by device type
                if (append.includes('device=')) {
                    append = append.replace('device=', 'deviceId=');
                } else {
                    append += `&deviceId=${this.deviceType}`;
                }
                // Always fetch only active sessions
                append += '&active=true';
                if (this.isAdmin) {
                    if (this.$store.getters.arena) {
                        response = await Session.api.getByArena(this.$store.getters.arena.id, `${append}`);
                    } else {
                        response = await Session.api.get(`${append}`);
                    }
                } else {
                    response = await Session.api.getByArena(this.$store.getters.arena ? this.$store.getters.arena.id : -1, `${append}`);
                }
                const data = response.data;
                if (this.deviceType === "computers") {
                    await this.fetchSessionDetails(data);
                } else {
                    this.entities = data.content.map(SessionDTO => {
                        const session = new Session(SessionDTO);
                        session.balance = SessionDTO.coin_balance
                        return session;
                    });
                }
                this.pagination.current_page = data.pageable.page_number + 1;
                this.pagination.per_page = data.pageable.page_size;
                this.pagination.total = data.total_elements;
                this.pagination.last_page = data.total_pages;
                this.pagination.from = data.pageable.offset + 1;
                this.pagination.to = this.pagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn(`User sessions API failed.`);
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async fetchSessionDetails(data) {
            const sessionIds = data.content.map(SessionDTO => SessionDTO.id).join(',');
            if (!sessionIds) {
                return;
            }
            let sessionGamesListData = [];
            try {
                const response = await Session.api.getSessionsGames(this.$store.getters.arena.id, `session_ids=${sessionIds}&currently_playing=true`);
                sessionGamesListData = response.data.data;
            } catch (e) {
                console.warn(`failed to fetch session details`);
                console.log(e);
            }
            this.entities = data.content.map(SessionDTO => {
                const session = new Session(SessionDTO);
                session.balance = SessionDTO.coin_balance
                const sessionGamesData = sessionGamesListData.find(s => s.sessionId === SessionDTO.id);
                session.games = [];
                if (sessionGamesData) {
                    session.games = sessionGamesData.games;
                }
                return session;
            });

        },
        async openViewDialog(entity) {
            this.editedEntity = await this.getUser(entity.username);
            this.viewDialog = true;
        },
        async stopSession(entity) {
            try {
                this.loadingIndexes.push(entity.id);
                this.loading = true;
                const data = {
                    reason: 'closed_by_employee',
                };
                await Session.api.destroy(this.$store.getters.arena.id, entity.id, data)
                    .then(() => {
                        this.refresh();
                    }).catch(error => {
                        this.loading = false;
                        // Server error
                        if (error.response.status === 500) {
                            this.showSnackbar('error', error.response.data);
                        }
                    });
            } catch (error) {
                console.warn(`Delete Arena Session API failed.`);
                console.log(error);
            } finally {
                this.loadingIndexes = this.loadingIndexes.filter(i => i !== entity.id)
            }
        },
        async getUser(username) {
            const response = await User.api.findByUsername(username);
            return response.data;
        },
        async approveIdentity(id) {
            try {
                this.approvingIdentity = true;
                await User.api.approveIdentity(id)
                    .then(response => {
                        this.editedEntity.identity.status = response.data.status;
                        this.showSnackbar('success', this.$t(`entities.users.notifications.approveIdentity`));
                    }).catch(error => {
                        if (error.response.status === 403) {
                            this.showSnackbar('error', error.response.data.message);
                        }
                    });
            } catch (error) {
                console.warn(`User Approve Identity API failed.`);
                console.log(error);
            } finally {
                this.approvingIdentity = false;
            }
        },
        async rejectIdentity(id) {
            try {
                this.rejectingIdentity = true;
                await User.api.rejectIdentity(id)
                    .then(response => {
                        this.editedEntity.identity.status = response.data.status;
                        this.showSnackbar('success', this.$t(`entities.users.notifications.rejectIdentity`));
                    }).catch(error => {
                        this.axiosCatch(error);
                    });
            } catch (error) {
                console.warn(`User Reject Identity API failed.`);
                console.log(error);
            } finally {
                this.rejectingIdentity = false;
            }
        },
        async createPurchase(session) {
            const user = await this.getUser(session.username);
            this.$router.push({
                name: 'navigationDrawer.shop.purchase',
                params: {
                    arena: this.arenaName,
                    user: JSON.stringify(user)
                }
            });
        },
        async openAwardDialog(entity) {
            this.loadingAward = true;
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            this.awardDialog = true;
            this.loadingAward = false;
        },
        awardEvent() {
            if (!this.$refs.awardForm.validate()) {
                return;
            }

            this.awardingEvent = true;

            const data = {
                user_id: this.editedEntity.user_id,
                username: this.editedEntity.username,
                events: this.editedEntity.events.map(event => ({
                    context: event.context,
                    data: { value: 1 }
                }))
            };

            User.api.awardEvent(data)
                .catch(error => {
                    this.showSnackbar('error', error.response.data);
                });
            this.awardingEvent = false;
            this.awardDialog = false;
            this.showSnackbar('success', this.$t(`entities.users.notifications.progress`));
        },
        shouldChangeRowStyle(session) {
            return session.rate_per_minute > 0 && session.balance < session.negative_balance + 300;
        },
        highlightRowIfUserBalanceLow(session) {
            if (this.shouldChangeRowStyle(session)) {
                return 'error white--text do-not-apply-hover';
            }
        },
        refresh() {
            this.fetchEntities();
        },
        wasPolledInLastNMinutes(item, minutes = 1) { 
            return (new Date().getTime() - new Date(item.last_polling_date).getTime()) < 60000*minutes;
        }

    }
});

</script>
