import { AxiosResponse } from 'axios';
import axios from '@/services/axios';

export default abstract class CompaniesApi {

    public static route = process.env.VUE_APP_ARENA_HUB_BASE_URI;
    public static entity = 'companies';

    public static async get(params: string): Promise<AxiosResponse> {
        return await axios.get(`${this.route}/${this.entity}?${params}`);
    }
}

